<!-- eslint-disable no-useless-escape -->
<template>
  <div id="chatModal" v-if="chatConfig" :class="{ chatModal: true, chatPosition: collapsed, [`${widgetPosition}Chat`]: slideIn, original: chatSizes.original.current && slideIn, small: chatSizes.small.current && slideIn }">
    <button v-if="botId && widgetIcon && !slideIn" id="modalButton" :class="[widgetButtonClasses]" @click="toggleSlide" aria-label="Toggle Chat">
      <img id="seezarIcon" alt="Seezar icon" :src="widgetIcon" :class="[{ [widgetAnimation]: animationStart }]" />
      <div v-if="typingMessage && welcomeMessage && animated" :class="{ tooltipMessage: true, [widgetPosition]: true }">
        <Tooltip :position="tooltipPosition"
          ><span>{{ typingMessage }}</span></Tooltip
        >
      </div>
    </button>

    <div :class="{ modalBackdrop: slideIn, collapsed: collapsed }">
      <div :style="controlDisplayProperty" :class="{ content: true, slideIn: slideIn }">
        <Chat :custom-attributes="customAttributes" :bot-id="botId" :dealership-id="dealershipId" :franchise-id="franchiseId" :dark-theme="darkTheme" :bot-config="dealershipSeezarBot" @on-open-chat-modal="handleChatOpen" :is-chat-open="slideIn">
          <template #modalHeaderIcon>
            <div class="modalHeaderIcon">
              <div class="headerIcon">
                <img id="headerBrandIcon" alt="Seezar icon" :src="headerIcon" />
              </div>
            </div>
          </template>
          <template #buttonsWrapper>
            <div class="chatSizeMenuWrapper">
              <button id="expandButton" class="expandButton" @click.stop="onClickExpand" aria-label="Expand Chat">
                <ExpandIcon aria-label="Expand Chat" />
              </button>
              <div v-if="displayChatSizeOptions" :class="['chatSizeMenu', textDirection]">
                <div :class="['chatSizeArrow', textDirection]" />
                <ul class="sizeOptionList">
                  <li class="sizeOption" v-for="size in showChatSizeOptions" :key="size" @click="changeChatSize(size)" :aria-label="`Chat size - ${size}`">
                    <div class="mainScreen">
                      <div :class="chatSizes[size].icon" class="commonScreen" />
                    </div>
                    {{ chatSizeDisplayNames[size] }}
                  </li>
                </ul>
              </div>
            </div>

            <button id="closeButton" class="closeButton" @click="toggleSlide" aria-label="Close Chat">
              <CloseIcon aria-label="Close Chat" />
            </button>
          </template>
        </Chat>
      </div>
    </div>
  </div>
</template>

<script>
import Chat from './ChatComponent.ce.vue'
import { hexToRgba } from './helpers'
import { getSeezarConfig, getSeezarConfigByClientPath, getSeezarConfigByBotId } from './api'
import { analyticsMixin } from '@/analytics.js'
import ExpandIcon from '../../assets/expand.svg'
import CloseIcon from '../../assets/close.svg'
import light from './themes/light.json'
import dark from './themes/dark.json'
import Tooltip from './Tooltip.ce.vue'
import { langMixin } from '../../components/lang.js'

const customAttributes = {
  chatStartTime: true,
  automatedChatStart: true,
  longFirstMessage: true
}

const booleanCustomAttributes = {
  automatedChatStart: true
}

const WidgetPositions = {
  MIDDLE_LEFT: 'middleLeft',
  MIDDLE_RIGHT: 'middleRight',
  TOP_LEFT: 'topLeft',
  TOP_RIGHT: 'topRight',
  BOTTOM_LEFT: 'bottomLeft',
  BOTTOM_RIGHT: 'bottomRight'
}

const WidgetMargins = {
  MARGIN_SMALL: 'marginSmall',
  MARGIN_MEDIUM: 'marginMedium',
  MARGIN_LARGE: 'marginLarge',
  MARGIN_NONE: 'marginNone'
}

const BorderRadius = {
  SQUARE: 'square',
  ROUNDED: 'rounded'
}

const WidgetAnimations = {
  EXPAND: 'expand',
  SHAKE_HORIZONTAL: 'shakeHorizontal',
  SHAKE_VERTICAL: 'shakeVertical',
  FLIP: 'flip',
  SWING: 'swing',
  BOUNCE: 'bounce',
  NONE: 'none'
}

const chatSizeKeys = {
  full: 'full',
  original: 'original',
  small: 'small'
}

export default {
  name: 'ChatModal',
  components: { Chat, CloseIcon, Tooltip, ExpandIcon },
  mixins: [analyticsMixin, langMixin('CHAT_COMPONENT_TRANSLATIONS')],
  props: {
    dealershipId: { type: String, default: '' },
    franchiseId: { type: String, default: '' },
    defaultTheme: { type: String, default: '' },
    passedBotId: { type: String, default: '' }
  },
  emits: ['close'],
  data() {
    // const userPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
    return {
      slideIn: false,
      chatConfig: null,
      darkTheme: false,
      initialBodyOverflow: '',
      hideElement: true,
      dealershipSeezarBot: null,
      widgetIcon: '',
      headerIcon: '',
      widgetPosition: WidgetPositions.MIDDLE_RIGHT,
      botId: '',
      widgetMargin: WidgetMargins.MARGIN_NONE,
      customCss: '',
      typingMessage: '',
      welcomeMessage: '',
      typingSpeed: 90,
      widgetAnimation: WidgetAnimations.NONE,
      animated: true,
      animationStart: true,
      animationInterval: 8000,
      animationRepeat: false,
      animationIntervalFunction: null,
      chatSizes: {
        [chatSizeKeys.full]: {
          current: false,
          icon: 'fullScreen'
        },
        [chatSizeKeys.small]: {
          current: true,
          icon: 'smallScreen'
        },
        [chatSizeKeys.original]: {
          current: false,
          icon: 'originalScreen'
        }
      },
      displayChatSizeOptions: false,
      fallBackChatStartTime: 5,
      isNotMobileScreen: true,
      typingInterval: false,
      customAttributes: {},
      chatReferenceId: '',
      chatUuid: ''
    }
  },
  computed: {
    tooltipPosition() {
      const positionMap = {
        [WidgetPositions.TOP_LEFT]: 'bottomLeft',
        [WidgetPositions.MIDDLE_LEFT]: 'topLeft',
        [WidgetPositions.BOTTOM_LEFT]: 'topLeft',
        [WidgetPositions.TOP_RIGHT]: 'bottomRight',
        [WidgetPositions.MIDDLE_RIGHT]: 'topRight',
        [WidgetPositions.BOTTOM_RIGHT]: 'topRight'
      }

      return positionMap[this.widgetPosition] || ''
    },
    isDefaultIconSelected() {
      return this.isDefaultWidgetIcon(this.widgetIcon, this.dealershipSeezarBot?.widgetIcons || [])
    },
    widgetButtonClasses() {
      return { slideButton: true, customIcon: this.isDefaultIconSelected, [this.widgetPosition]: true, [this.widgetMargin]: true, squared: this.dealershipSeezarBot?.borderRadius === BorderRadius.SQUARE }
    },
    controlDisplayProperty() {
      return this.hideElement ? 'display: none' : 'display: grid'
    },
    icon() {
      return this.dealershipSeezarBot.config?.customAvatarImages[0] || this.chatConfig?.theme?.modalHeadingIcon
    },
    chatModalStyles() {
      return {
        '--font-family': this.dealershipSeezarBot?.font,
        '--primary-color': this.dealershipSeezarBot?.primaryColor,
        '--modal-bg-color': this.chatConfig?.theme.chatModalStyles.modalBgColor,
        '--chat-modal-header-bg-color': this.chatConfig?.theme.chatModalStyles.chatModalHeaderBgColor,
        '--chat-modal-header-text-color': this.chatConfig?.theme.chatModalStyles.chatModalHeaderTextColor,
        '--chat-modal-content-border-radius': this.chatConfig?.theme.chatModalStyles.chatModalContentBorderRadius,
        '--chat-modal-header-border-bottom-color': this.chatConfig?.theme.chatModalStyles.chatModalHeaderBorderBottomColor,
        '--chat-modal-slider-button-bg-color': this.chatConfig?.theme.chatModalStyles.chatModalSliderButtonBgColor || '',
        '--shadow-from-primary-color': `0 4px 6px ${hexToRgba(this.dealershipSeezarBot?.primaryColor, '0.3')}`,
        '--chat-size-background': this.darkTheme ? 'black' : 'white',
        '--chat-size-border': this.darkTheme ? 'white' : 'black'
      }
    },
    bodyOverflow() {
      return this.slideIn && (!this.collapsed || !this.isNotMobileScreen) ? 'hidden' : this.initialBodyOverflow
    },
    modalHeaderText() {
      return this.chatConfig.modalHeadingText
    },
    showChatSizeOptions() {
      return Object.keys(this.chatSizes).filter(size => !this.chatSizes[size].current)
    },
    collapsed() {
      return this.chatSizes.original.current || this.chatSizes.small.current
    },
    chatSizeDisplayNames() {
      return {
        [chatSizeKeys.full]: this.t.fullSize,
        [chatSizeKeys.small]: this.t?.smallSize,
        [chatSizeKeys.original]: this.t?.originalSize
      }
    },
    chatOpensOnRight() {
      return this.widgetPosition.endsWith('Right') ? true : false
    }
  },
  trackData: ['botId', 'franchiseId', 'dealershipId', 'chatReferenceId', 'chatUuid'],
  watch: {
    animationInterval() {
      this.startAnimationRepeat()
    },
    animationRepeat(newValue) {
      if (!newValue && this.animationInterval) {
        clearInterval(this.animationInterval)
      } else {
        this.startAnimationRepeat()
      }
    },
    slideIn(v) {
      if (!v) {
        setTimeout(() => {
          this.animated = true
          this.startTypingAnimation(false)
        }, 5000)
      } else {
        clearInterval(this.typingInterval)
      }
    },
    bodyOverflow(newValue) {
      document.body.style.overflow = newValue
    },
    dealershipSeezarBot(data) {
      if (data) {
        this.loadFontStylesheet()
      }
    },
    'dealershipSeezarBot.customAvatarImages': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.handleLoadIcons()
        }
      }
    },
    chatModalStyles() {
      this.injectThemeStyles()
    },
    displayChatSizeOptions(newValue) {
      if (newValue) document.addEventListener('click', this.onClickOutsideChatSizeoptions)
      else document.removeEventListener('click', this.onClickOutsideChatSizeoptions)
    },
    t() {}
  },
  created() {
    this.initialBodyOverflow = window.getComputedStyle(document.body).overflow
  },
  mounted() {
    this.getDefaultConfig()
    this.getSeezarConfig()
    this.checkViewportAndExpand()
    this.injectThemeStyles()
    this.startAnimationRepeat()

    if (this.welcomeMessage) {
      setTimeout(() => this.startTypingAnimation(), 2000)
    }
    this.mediaQueryList = window.matchMedia('(min-width: 768px)')
    this.mediaQueryList.addListener(this.checkScreenSize)
    this.checkScreenSize()
  },
  beforeUnmount() {
    document.body.style.overflow = this.initialBodyOverflow
    if (this.animationInterval) {
      clearInterval(this.animationInterval)
    }
  },
  methods: {
    startAnimationRepeat() {
      if (!this.animationRepeat) {
        if (this.animationIntervalFunction) {
          clearInterval(this.animationIntervalFunction)
        }
        return
      }
      if (this.animationIntervalFunction) {
        clearInterval(this.animationIntervalFunction)
      }

      this.animationIntervalFunction = setInterval(() => {
        this.animationStart = !this.animationStart
      }, this.animationInterval)
    },
    startTypingAnimation(openModal = true) {
      const message = this.welcomeMessage
      let index = 0

      this.typingInterval = setInterval(() => {
        if (index < message.length) {
          this.typingMessage += message[index]
          index++
        } else {
          clearInterval(this.typingInterval)
          if (openModal) this.automaticChatOpen()
        }
      }, this.typingSpeed)
    },
    checkViewportAndExpand() {
      const maxWidthForMobileOrTablet = 768
      if (window.innerWidth <= maxWidthForMobileOrTablet) {
        this.changeChatSize(chatSizeKeys.full)
      }
    },
    isDefaultWidgetIcon(url, array) {
      // eslint-disable-next-line no-useless-escape
      const extractedPart = url.replace(/^https?:\/\/[^\/]+\/[^\/]+\/[^\/]+\//, '').replace(/\.[^\.]+$/, '')
      const index = array.findIndex(item => item === extractedPart)
      return index !== -1 ? true : false
    },
    loadFontStylesheet() {
      if (!this.dealershipSeezarBot.font) return
      const fontName = this.dealershipSeezarBot.font || 'Roboto'

      const existingFontLink = document.querySelector('link[data-font-stylesheet]')
      if (existingFontLink) {
        existingFontLink.parentNode.removeChild(existingFontLink)
      }

      const fontLink = document.createElement('link')
      fontLink.rel = 'stylesheet'
      fontLink.href = `https://fonts.googleapis.com/css2?family=${fontName}:wght@400;700&display=swap`
      fontLink.dataset.fontStylesheet = true

      document.head.appendChild(fontLink)
    },
    setDefaultHeaderIcon() {
      if (this.dealershipSeezarBot?.customAvatarImages?.[0]) return
      const iconUrl = this.darkTheme ? 'https://seez-platform-uploads.s3.eu-central-1.amazonaws.com/images/icons/icons/seezar_dark.png' : 'https://seez-platform-uploads.s3.eu-central-1.amazonaws.com/images/icons/icons/seezar_light.png'
      this.headerIcon = iconUrl
    },
    setDefaultWidgetIcon() {
      this.widgetIcon = 'https://seez-platform-uploads.s3.eu-central-1.amazonaws.com/images/seezar/SeezarIcon.png'
    },
    handleLoadIcons() {
      if (this.dealershipSeezarBot?.widgetIcon) {
        this.widgetIcon = `${import.meta.env.VITE_CHAT_IMAGE_URL}/image/0x0/${this.dealershipSeezarBot?.widgetIcon}.webp`
      } else {
        this.setDefaultWidgetIcon()
      }
      if (this.dealershipSeezarBot?.customAvatarImages?.length > 0) {
        this.headerIcon = `${import.meta.env.VITE_CHAT_IMAGE_URL}/image/0x0/${this.dealershipSeezarBot?.customAvatarImages[0]}.webp`
      } else {
        this.setDefaultHeaderIcon()
      }
    },
    async getSeezarConfigByPath(pathname, websiteUrl) {
      const result = await getSeezarConfigByClientPath(pathname, websiteUrl)

      if (!result.clientSeezarBot) {
        this.getDefaultConfig()
        this.handleLoadIcons()
        this.botId = null
        return
      }

      this.dealershipSeezarBot = result.clientSeezarBot.config
      this.setCustomAttributes()

      this.botId = result.clientSeezarBot.id

      if (this.dealershipSeezarBot.widgetPosition) {
        this.widgetPosition = this.dealershipSeezarBot.widgetPosition
      }
      if (this.dealershipSeezarBot.welcomeMessage) {
        this.welcomeMessage = this.dealershipSeezarBot.welcomeMessage
        setTimeout(() => {
          this.startTypingAnimation()
        }, 2000)
      } else this.automaticChatOpen()

      if (this.dealershipSeezarBot.customCss) {
        const customCss = JSON.parse(this.dealershipSeezarBot.customCss)
        this.widgetMargin = customCss?.widgetMargin || 'none'
        this.widgetAnimation = customCss?.widgetAnimation || WidgetAnimations.NONE
        this.animationRepeat = customCss?.animationRepeat
      }

      this.handleLoadIcons()
    },
    async getSeezarConfig() {
      const url = new URL(window.location.href)

      const pathname = url.pathname
      const websiteUrl = url.href
      const local = url.hostname === 'localhost'
      const seezURL = url.origin === import.meta.env.VITE_SEEZ_BASE_URL || local

      if (this.passedBotId) {
        this.botId = this.passedBotId
        const result = await getSeezarConfigByBotId(this.passedBotId)
        this.setupDealershipSeezarBot(result.seezarBot)
        return
      }

      if (this.dealershipId) {
        const result = await getSeezarConfig(this.dealershipId, this.franchiseId)
        this.setupDealershipSeezarBot(result.dealershipSeezarBot)
        return
      }

      if (seezURL) {
        this.getDefaultConfig()
        this.handleLoadIcons()
        //Seez bot id
        this.botId = '1'
        this.automaticChatOpen()
        return
      }

      this.getSeezarConfigByPath(pathname, websiteUrl)
    },
    setupDealershipSeezarBot(result) {
      this.dealershipSeezarBot = result.config
      this.setCustomAttributes()
      this.botId = result.id
      this.widgetPosition = this.dealershipSeezarBot.widgetPosition

      if (this.dealershipSeezarBot.customCss) {
        const customCss = JSON.parse(this.dealershipSeezarBot.customCss)
        this.widgetMargin = customCss?.widgetMargin || 'none'
        this.widgetAnimation = customCss?.widgetAnimation || WidgetAnimations.NONE
        this.animationRepeat = customCss?.animationRepeat
      }

      if (this.dealershipSeezarBot.welcomeMessage) {
        this.welcomeMessage = this.dealershipSeezarBot.welcomeMessage
        setTimeout(() => {
          this.startTypingAnimation()
        }, 2000)
      } else this.automaticChatOpen()

      this.handleLoadIcons()
    },
    async getDefaultConfig() {
      if (this.defaultTheme === 'dark') {
        return (this.chatConfig = dark.chatConfig)
      }
      this.handl
      return (this.chatConfig = light.chatConfig)
    },
    toggleSlide() {
      this.animated = false
      this.typingMessage = ''
      if (this.slideIn) {
        this.slideIn = !this.slideIn
        this.hideElement = !this.hideElement
        this.$emit('close')
        this.track('chat_close')
      } else {
        this.hideElement = !this.hideElement
        setTimeout(() => {
          this.slideIn = !this.slideIn
        }, [100])
        this.track('chat_open')
      }
    },
    onClickChangeTheme() {
      this.darkTheme = !this.darkTheme
      this.track('chat_change_theme', { theme: this.darkTheme ? 'dark' : 'light' })
      this.setDefaultHeaderIcon()
      this.chatConfig = this.darkTheme ? dark.chatConfig : light.chatConfig
    },
    injectThemeStyles() {
      const styleSheet = document.createElement('style')
      let cssString = Object.entries(this.chatModalStyles)
        .filter(p => p[1] != null)
        .map(p => `${p[0]}: ${p[1]};`)
        .join(' ')
      if (this.dealershipSeezarBot?.customCss) cssString += ' ' + this.dealershipSeezarBot.customCss
      styleSheet.innerHTML = `.chatModal { ${cssString} }`
      styleSheet.setAttribute('data-custom', 'seez-modal')
      const rootNode = this.$el.getRootNode()
      rootNode.querySelectorAll('[data-custom="seez-modal"]').forEach(x => x.remove())
      const firstNoStyle = rootNode.querySelector(':not(style)')
      if (firstNoStyle) rootNode.insertBefore(styleSheet, firstNoStyle)
      else rootNode.prepend(styleSheet)
    },
    async automaticChatOpen() {
      if (!this.customAttributes.automatedChatStart) return
      const chatStartTime = this.customAttributes.chatStartTime
      const seconds = !chatStartTime || isNaN(chatStartTime) || chatStartTime < 0 ? this.fallBackChatStartTime : chatStartTime
      setTimeout(() => {
        this.handleChatOpen()
      }, seconds * 1000)
    },
    changeChatSize(newSize) {
      Object.keys(this.chatSizes).forEach(size => {
        if (size === newSize) this.chatSizes[size].current = true
        else this.chatSizes[size].current = false
      })

      this.track('chat_resize', { size: newSize })
      this.displayChatSizeOptions = false
    },
    onClickExpand() {
      this.displayChatSizeOptions = !this.displayChatSizeOptions
    },
    onClickOutsideChatSizeoptions() {
      this.displayChatSizeOptions = false
    },
    setCustomAttributes() {
      if (!this.dealershipSeezarBot) return

      if (this.dealershipSeezarBot.customAttributes) {
        for (const attr of this.dealershipSeezarBot.customAttributes) {
          if (customAttributes[attr.key]) {
            if (booleanCustomAttributes[attr.key]) this.customAttributes[attr.key] = attr.value === 'true'
            else this.customAttributes[attr.key] = attr.value
          }
        }
      }
    },
    handleChatOpen() {
      if (!this.slideIn) this.toggleSlide()
    },
    checkScreenSize() {
      this.isNotMobileScreen = this.mediaQueryList.matches
    }
  }
}
</script>

<style lang="scss">
.chatModal {
  transition: all 0.3s ease-out;
  font-family: var(--font-family), sans-serif;
  position: fixed;
  z-index: 10000;
  font-size: 16px;

  &.middleRightChat,
  &.topRightChat,
  &.bottomRightChat {
    .commonScreen {
      left: unset;
      right: 1px;
    }
  }

  &.middleLeftChat,
  &.topLeftChat,
  &.bottomLeftChat {
    .commonScreen {
      left: 1px;
      right: unset;
    }
  }

  &.chatPosition {
    transition: all 0.3s ease-in-out;

    @media screen and (min-width: 768px) {
      &.middleRightChat,
      &.topRightChat,
      &.bottomRightChat {
        left: unset;
        right: 1em;
      }

      &.middleLeftChat,
      &.topLeftChat,
      &.bottomLeftChat {
        left: 1em;
        right: unset;
      }
    }
  }
}

.original {
  top: 0em;
  bottom: 0em;
  left: 0em;
  right: 0em;
  @media screen and (min-width: 768px) {
    width: 34em;
    top: 1em;
    bottom: 1em;
  }
}

.small {
  bottom: 0em;
  top: 0em;
  left: 0em;
  right: 0em;
  @media screen and (min-width: 768px) {
    height: 34em;
    width: 26em;
    bottom: 1em;
    top: unset;
  }
}

@keyframes circleAnimation {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.modalBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: all 0.2s ease-in-out;
  z-index: 3000;

  &.collapsed {
    position: initial;
    display: contents;
  }

  @media screen and (min-width: 845px) {
    padding: 1em;
  }
}

.content {
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;
  background-color: var(--modal-bg-color);
  overflow: auto;
  position: relative;
  opacity: 0;
  visibility: hidden;
  box-shadow:
    1px 2px 27px 0px rgba(0, 0, 0, 0.5),
    -3px -2px 10px 0px rgba(0, 0, 0, 0.15);
  transform: v-bind('chatOpensOnRight ? "translateX(110%)" : "translateX(-110%)"');
  transition:
    transform 1s ease-in-out,
    opacity 1s ease-in-out,
    visibility 0s linear 1s;

  &.display {
    display: none;
  }

  @media screen and (min-width: 480px) {
    border-radius: var(--chat-modal-content-border-radius);
  }
}

.slideIn {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
  transition:
    transform 0.5s ease-in-out,
    opacity 0.5s ease-in-out,
    visibility 0s linear;
  display: block;
}

.modalHeader {
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  border-bottom: 1px solid var(--chat-modal-header-border-bottom-color);
  color: var(--chat-modal-header-text-color);
  background-color: var(--modal-bg-color);
}

.modalHeader.rtl {
  direction: rtl;
}

.modalHeader.ltr {
  direction: ltr;
}

.headerIcon {
  width: 120px;
  height: 30px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.modalHeaderIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  text-align: center;

  > img {
    width: 8.875em;
    max-height: 2.438em;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
  }
}

.slideButton {
  direction: ltr;
  position: fixed;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
  border: none;
  cursor: pointer;
  z-index: 999;
  background-color: var(--chat-modal-slider-button-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 101px;
  border-bottom-left-radius: 101px;
  overflow: visible;

  > img {
    width: auto;
    height: auto;
    max-width: 60px;
    max-height: 150px;
    object-fit: cover;
  }

  > .tooltipMessage {
    position: absolute;
    border-radius: 8px;
    color: var(--chat-modal-header-text-color);
    font-size: 12.8px;
    line-height: 1.2em;
    padding: 0.5em;
    top: -63px;
    right: 92%;
    width: 12.5em;
    z-index: 999;
    overflow: visible;
    direction: v-bind(textDirection);

    &.topRight {
      top: 100%;
      left: auto;
      bottom: auto;
      transform: translateY(0);
    }

    &.topLeft {
      top: 100%;
      left: 106%;
    }

    &.middleLeft,
    &.bottomLeft {
      left: 106%;
    }
  }
}

.topRight {
  top: 0;
  left: auto;
  bottom: auto;
  transform: translateY(0);
}
.bottomRight {
  bottom: 0;
  top: auto;
  left: auto;
  transform: translateY(0);
}
.bottomLeft {
  bottom: 0;
  left: 0;
  top: auto;
  right: auto;
  transform: translateY(0);
}
.topLeft {
  top: 0;
  transform: translateY(0);
  left: 0;
  bottom: auto;
  right: auto;
}

.middleLeft {
  top: 50%;
  left: 0;
  bottom: auto;
  right: auto;
}
.middleRight {
  top: 50%;
  left: auto;
  bottom: auto;
}

.marginSmall {
  margin: 0.5em;
}
.marginMedium {
  margin: 1em;
}
.marginLarge {
  margin: 2em;
}
.marginNone {
  margin: 0;
}

.customIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border-radius: 48px;
  width: 60px;
  height: 60px;
  padding: 2em;

  > img {
    width: auto;
    height: auto;
    max-width: 32px;
    max-height: 50px;
    object-fit: cover;
    transform: translateY(0.1em);
  }

  &.squared {
    border-radius: 8px;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.buttonTheme {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.expandButton,
.closeButton,
.clearChatButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

// widget animations start
.expand {
  animation: expandAndShrink 2s ease-in-out normal;
  @keyframes expandAndShrink {
    0%,
    100% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.3);
      opacity: 1;
    }
  }
}

.shakeHorizontal {
  animation: shake 2s ease-in-out normal;
  @keyframes shake {
    0%,
    100% {
      transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translateX(-10px);
    }
    20%,
    40%,
    60%,
    80% {
      transform: translateX(10px);
    }
  }
}

.shakeVertical {
  animation: shakeAt45Degrees 2.5s ease-in-out normal;
  @keyframes shakeAt45Degrees {
    0%,
    100% {
      transform: rotate(0deg) translate(0, 0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: rotate(-45deg) translate(-5px, 5px);
    }
    20%,
    40%,
    60%,
    80% {
      transform: rotate(45deg) translate(5px, -5px);
    }
  }
}

.bounce {
  animation: bounce 2s ease-in-out normal;
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
}

.pulse {
  animation: pulse 2s ease-in-out normal;
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
}

.swing {
  animation: swing 2s ease-in-out normal;
  @keyframes swing {
    20% {
      transform: rotate(80deg);
    }
    40% {
      transform: rotate(-40deg);
    }
    60% {
      transform: rotate(80deg);
    }
    80% {
      transform: rotate(-40deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}

.flip {
  animation: flip 2s ease-in-out normal;
  @keyframes flip {
    0% {
      transform: rotateY(0);
    }
    50% {
      transform: rotateY(180deg);
    }
    100% {
      transform: rotateY(0);
    }
  }
}

.chatSizeMenuWrapper {
  position: relative;
}

.chatSizeMenu {
  position: absolute;
  top: 32px;
  z-index: 5000;
  border: 1px solid #efefef;
  border-radius: 1em;
  background-color: var(--modal-bg-color);

  padding: 1em;
  width: 120px;
  box-shadow: 0px 13.668px 31.891px 0px rgba(167, 174, 186, 0.16);
  direction: var(--text-direction);
}

.chatSizeMenu.ltr {
  direction: ltr;
  right: -37px;
}

.chatSizeMenu.rtl {
  direction: rtl;
  left: -37px;
}

.sizeOptionList {
  margin: 0;
  list-style-type: none;
  padding: 0;

  :not(:first-child) {
    margin-top: 0.5em;
  }
}

.sizeOption {
  font-size: 0.75em;
  cursor: pointer;
  display: flex;
  gap: 0.5em;
}

.chatSizeArrow {
  width: 15px;
  height: 15px;
  background-color: var(--modal-bg-color);
  position: absolute;
  top: -9px;
  border-top: 1px solid #efefef;
  border-left: 1px solid #efefef;
  transform: rotate(45deg);
}

.chatSizeArrow.ltr {
  left: 96px;
}

.chatSizeArrow.rtl {
  right: 96px;
}

.mainScreen {
  min-width: 20px;
  height: 15px;
  border-radius: 4px;
  position: relative;

  background-color: var(--chat-size-background);
  border: 1px solid var(--chat-size-border);
}

.originalScreen {
  width: 7px;
  height: 13px;
  top: 1px;
}

.smallScreen {
  width: 7px;
  height: 7px;
  bottom: 1px;
}

.fullScreen {
  width: 18px;
  height: 13px;
  top: 1px;
}

.commonScreen {
  background: linear-gradient(122deg, #00ffc2 0%, #009974 109%);
  position: absolute;
  border-radius: 2px;
}
</style>
