<template>
  <div :class="['modalHeader', textDirection]">
    <slot name="modalHeaderIcon" />
    <div :class="['actionButtons', textDirection]">
      <button id="todaysMessageButton" class="todaysMessageButton" @click="onClickTodaysMessageButton">{{ todaysText }}</button>
      <p v-if="chatReferenceId" class="chatReferenceId">• {{ chatReferenceText }}</p>
    </div>
    <div class="buttonsWrapper">
      <button :disabled="clearDisabled || disabled" v-if="!noMessage" id="clearChatButton" class="clearChatButton" :class="{ disabled: clearDisabled || disabled }" @click="onClickResetMessages">
        <TrashIcon />
      </button>
      <slot name="buttonsWrapper" />
    </div>
  </div>

  <div ref="chat" v-if="chatConfig" :class="{ chat: true }">
    <div ref="messages" @scroll="handleScroll" :class="{ messages: true }">
      <ChatMessageList :messages="messages" :theme="chatConfig.theme" :bot-config="dealershipSeezarBot">
        <template #autoScrollObserver>
          <div ref="endOfMessages" id="endOfMessages">
            <IntersectionObserver @on-intersection="handleEndOfMessagesVisibility" observed-ref-name="endOfMessages" />
          </div>
        </template>
        <template #automatedMessage>
          <ChatMessage id="bot" ref="message0" :message="firstMessage" :date="firstMessageDate" :chat-avatar-styles="chatConfig?.theme?.chatAvatarStyles" :chat-message-styes="chatConfig?.theme?.chatMessageStyles" role="bot" :last-child="!messages.length" :bot-config="dealershipSeezarBot" :show-faqs="!messages.length">
            <template #faqs>
              <FAQPills :faqs="handleFaqQuestions" @question-selected="handleFAQSelected" />
            </template>
          </ChatMessage>
        </template>
      </ChatMessageList>
    </div>
    <IntersectionObserver observed-ref-name="chat" @on-intersection="handleIntersection" />
    <div ref="inputWrapper" class="chatInputWrapper">
      <ChatInput id="chatInput" :placeholder="t?.textFieldPlaceholder || ''" @new-message="handleNewMessage" :theme="chatConfig.theme" :disabled="disabled" :bot-config="botConfig || dealershipSeezarBot" />
      <div v-if="!dealershipSeezarBot.termsAndConditions">
        <p class="disclaimerText">{{ t.newDisclaimerPartOne }}</p>
        <p class="disclaimerText">
          <span :class="['tooltipContainer', textDirection]">
            <QuestionMark />
            <span :class="['tooltipText', textDirection]"> {{ t.disclaimerTooltipText }}</span>
          </span>
          <span v-html="t.newDisclaimerPartTwo?.replace(/{seezarPrivacyPolicyUrl}/g, seezarPrivacyPolicyUrl)" />
        </p>
      </div>
      <div v-else class="customDisclaimerText" v-html="dealershipSeezarBot.termsAndConditions" />
    </div>
  </div>
</template>

<script>
import ChatMessageList from './ChatMessageList.ce.vue'
import IntersectionObserver from './IntersectionObserver.ce.vue'
import ChatInput from './ChatInput.ce.vue'
import TrashIcon from '../../assets/trashIcon.svg'
import { callCeaserApi, APIOperations, getSeezarConfig, getUserChatHistory, getSeezarConfigByBotId } from './api'
import light from './themes/light.json'
import dark from './themes/dark.json'
import { analyticsMixin } from '@/analytics.js'
import { langMixin } from '../../components/lang.js'
import FAQPills from './FAQPills.ce.vue'
import ChatMessage from './ChatMessage.ce.vue'
import QuestionMark from '../../assets/question-circle.svg'

const webSocketOperations = {
  HANDSHAKE: 'handShake',
  SEND_MESSAGE: 'sendMessage',
  PING: 'ping',
  PREPROCESSING: 'preProcessing',
  ERROR: 'message'
}

const reservedWebSocketStrings = {
  END_OF_CHAT: '{"done":true}',
  CONNECTED: 'success',
  PONG: 'pong'
}

const reservedWebSocketStringsKeys = {
  [reservedWebSocketStrings.END_OF_CHAT]: true,
  [reservedWebSocketStrings.CONNECTED]: true,
  [reservedWebSocketStrings.PONG]: true
}

const chatStates = {
  LOADING: 'loading',
  ERROR: 'error',
  CLEAR: 'clear'
}

const chatRoles = {
  USER: 'user',
  SYSTEM: 'system'
}

const iconThemes = {
  light: 'white',
  dark: 'black'
}

const radiusOptions = {
  square: '8px',
  rounded: '1.5em'
}

export default {
  name: 'ChatComponent',
  components: { ChatInput, ChatMessageList, TrashIcon, IntersectionObserver, FAQPills, ChatMessage, QuestionMark },
  mixins: [analyticsMixin, langMixin('CHAT_COMPONENT_TRANSLATIONS')],
  props: {
    dealershipId: { type: String, default: '' },
    franchiseId: { type: String, default: '' },
    darkTheme: { type: Boolean, default: false },
    botConfig: { type: Object, default: null },
    botId: { type: String, default: '' },
    customAttributes: { type: Object, default: () => {} },
    isChatOpen: { type: Boolean, default: true }
  },
  emits: ['onOpenChatModal'],
  data() {
    return {
      clearDisabled: false,
      lastScrollTop: 0,
      scrollingUp: false,
      scrollingDown: false,
      chatConfig: null,
      avatarStyles: null,
      dealershipSeezarBot: {
        sampleQuestions: []
      },
      messages: [],
      usage: null,
      chatReferenceId: '',
      loading: false,
      cachedFirstMessageDate: null,
      disabled: false,
      wsConnection: null,
      closeWsConnection: false,
      pingPongInterval: null,
      pingPongTimeGap: 5,
      wsClosedDueToError: false,
      wordsListLastIndex: 0,
      messageBuffer: '',
      errorBuffer: 2,
      errorsOccurred: 0,
      handShakeSent: false,
      chatUuid: ''
    }
  },
  computed: {
    seezarPrivacyPolicyUrl() {
      return `${import.meta.env.VITE_SEEZAR_PRIVACY_URL}`
    },
    chatReferenceText() {
      let refId = this.chatReferenceId

      if (this.textDirection === 'ltr') refId = `#${refId}`
      else refId += '#'

      return `${this.defaultMessages.CHAT_ID} ${refId}  `
    },
    handleFaqQuestions() {
      return this.dealershipSeezarBot?.sampleQuestions?.length > 0 ? this.dealershipSeezarBot?.sampleQuestions : this.chatConfig.faqQuestions
    },
    hasPastMassages() {
      const yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0)
      return this.messages.some(message => new Date(message.date) < yesterday)
    },
    todaysText() {
      return this.defaultMessages.TODAY
    },
    resetChatText() {
      const text = this.chatConfig.resetChatText
      return text || this.defaultMessages.RESET_CHAT
    },
    noMessage() {
      return this.messages.length === 0
    },
    chatComponentStyles() {
      const handleColor = color => {
        if (color === 'Transparent' || !color) return this.chatConfig?.theme?.chatInputStyles.buttonBgColor
        return color
      }

      return {
        '--chat-bg-color': this.chatConfig?.theme?.chatFAQStyles.itemBgColor,
        '--chat-base-text-color': this.chatConfig?.theme?.chatBaseTextColor,
        '--chat-primary-button-bg-color': this.chatConfig?.theme?.chatPrimaryButtonBgColor,
        '--chat-comparison-table-bg': this.chatConfig?.theme?.comparisonTableBgColor,
        '--link-color': this.chatConfig?.theme?.chatInputStyles.buttonBgColor,
        '--border-color': this.chatConfig?.theme?.chatInputStyles.borderColor,
        '--cta-bg-color': handleColor(this.botConfig?.primaryColor),
        '--cta-text-color': iconThemes[this.botConfig?.iconTheme || 'light'],
        '--cta-border-radius': radiusOptions[this.botConfig?.borderRadius || 'rounded'],
        '--text-direction': this.textDirection === 'rtl' ? 'rtl' : 'ltr',
        '--icon-rotate': this.textDirection === 'rtl' ? 'rotate(180deg)' : '',
        '--faq-gradient-background': this.darkTheme ? 'black' : 'white',
        '--faq-arrow-fill': this.darkTheme ? 'white' : 'black'
      }
    },
    defaultMessages() {
      return {
        TODAY: this.t.today,
        RESET_CHAT: 'Reset Chat',
        THINKING: this.t.thinking,
        DELETING_CHAT: 'Clearing chat history',
        CHAT_ID: this.t.chatId
      }
    },
    firstMessage() {
      if (this.customAttributes.longFirstMessage) return this.customAttributes.longFirstMessage
      return this.dealershipSeezarBot?.welcomeMessage ? this.dealershipSeezarBot?.welcomeMessage : `Hello, I'm ${this.dealershipSeezarBot?.botName || 'Seezar'} 👋 I'm your personal assistant. How can I help you?`
    },
    firstMessageDate() {
      if (this.cachedFirstMessageDate) {
        return this.cachedFirstMessageDate
      }
      return this.calculateFirstMessageDate()
    },
    messageListLastIndex() {
      return this.messages.length - 1
    },
    errorMessages() {
      return {
        ERROR_LOADING_MESSAGE: this.t?.errorLoadingMessage,
        WEB_SOCKET_ERROR: this.t?.wsConnectionError || 'Unable to connect, please try again later'
      }
    },
    toolMapping() {
      return {
        faq_search: {
          text: this.t.faq_search
        },
        inventory_search: {
          text: this.t.inventory_search
        },
        create_carousel_component_tool: {
          text: this.t.create_carousel_component_tool
        },
        create_comparison_component_tool: {
          text: this.t.create_comparison_component_tool
        },
        create_leads_form_component_tool: {
          text: this.t.create_leads_form_component_tool
        },
        listing_lookup_tool: {
          text: this.t.listing_lookup_tool
        },
        create_location_cards_component_tool: {
          text: this.t.create_location_cards_component_tool
        },
        create_listing_location_tool: {
          text: this.t.create_listing_location_tool
        }
      }
    }
  },
  trackData: ['chatReferenceId', 'chatUuid'],
  watch: {
    botId() {
      this.getChatHistory()
      this.openWebSocketConnection()
    },
    messages() {
      this.scrollToBottom()
    },
    darkTheme(isDark) {
      this.switchTheme(isDark)
    },
    botConfig(config) {
      this.dealershipSeezarBot = { ...this.dealershipSeezarBot, ...config }
    },
    chatComponentStyles() {
      this.injectThemeStyles()
    },
    t() {},
    isChatOpen(v) {
      if (v) {
        this.openWebSocketConnection()
      } else {
        // if there is no response being streamed, close the connection
        if (!this.disabled) {
          this.closeWebSocketConnection()
        }
      }
    }
  },
  async mounted() {
    this.getSeezarConfig()
    this.getChatHistory()
    this.addClearListeners()
    this.injectThemeStyles()
    window.addEventListener('externallyOpenChatModal', this.externallyOpenChatModal)
  },
  beforeUnmount() {
    const scrollContainer = this.$refs.messages
    scrollContainer.removeEventListener('scroll', this.handleUserScroll)
    this.closeWebSocketConnection()
    window.removeEventListener('externallyOpenChatModal', this.externallyOpenChatModal)
  },
  methods: {
    handleIntersection(isVisible) {
      if (isVisible) {
        this.scrollToBottom()
      }
    },
    parseChatMessages(chatData) {
      const parsedMessages = chatData
        .map((message, index) => ({
          id: `message${index + 1}`,
          message: this.bbCodeToHTML(message.content),
          author: { role: message.role === 'assistant' ? 'bot' : 'user' },
          date: message.sent_at
        }))
        .sort((a, b) => {
          return new Date(a.date) - new Date(b.date)
        })

      return parsedMessages
    },
    async getChatHistory() {
      this.loading = true

      if (!this.botId) return

      try {
        const chatHistoryData = await getUserChatHistory({ instanceId: +this.botId })
        const parsedMessages = this.parseChatMessages(chatHistoryData)
        this.messages = parsedMessages
        this.calculateFirstMessageDate()
        this.scrollToBottom()
      } catch (e) {
        this.error = true
        this.loading = false
        console.error(e)
        this.$root.alert = { type: 'Error', message: 'There was an error getting user chat' }
      } finally {
        this.loading = false
      }
    },
    async getSeezarConfig() {
      if (this.botConfig) {
        this.dealershipSeezarBot = this.botConfig
        return
      }

      if (!this.dealershipId && !this.botId) {
        this.chatConfig = this.darkTheme ? dark.chatConfig : light.chatConfig
        return
      }

      let result
      if (this.botId) {
        result = await getSeezarConfigByBotId(this.botId)
        this.dealershipSeezarBot = result.seezarBot.config
      } else {
        result = await getSeezarConfig(this.dealershipId, this.franchiseId)
        this.dealershipSeezarBot = result.dealershipSeezarBot.config
      }
      this.chatConfig = result.chatConfig
      this.avatarStyles = result.chatConfig.theme.chatAvatarStyles
    },
    bbCodeToHTML(str) {
      return str.replace(/\[url\](.*?)\[\/url\]/g, '<a href="$1" target="_blank">$1</a>')
    },
    getClientPath() {
      const url = new URL(window.location.href)
      return url.pathname
    },
    async sendMessageToAPI(newMessageObject) {
      this.setLoadingMessage()
      this.disabled = true
      const clientPath = this.getClientPath()

      try {
        const response = await callCeaserApi(APIOperations.SEND_MESSAGE, { message_in: newMessageObject.message, timestamp: newMessageObject.date.toISOString() }, this.botId, clientPath)
        const { message_out: message = '', chatReferenceId = '', usage = null, failsafeUrl = '' } = response
        const parsedMessage = this.bbCodeToHTML(message)
        this.chatReferenceId = chatReferenceId
        this.usage = usage

        if (failsafeUrl) {
          const errorMessage = `<a href="${failsafeUrl}" />${parsedMessage}</a>`
          this.replaceLoadingMessage(errorMessage, true)
          this.disabled = false
          return
        }
        if (!message) {
          this.replaceLoadingMessage(this.errorMessages.ERROR_LOADING_MESSAGE, true)
        }

        this.replaceLoadingMessage(parsedMessage)

        this.scrollToBottom()
      } catch (error) {
        console.error('Error sending message:', error)
        this.replaceLoadingMessage(this.errorMessages.ERROR_LOADING_MESSAGE, true)
      } finally {
        this.disabled = false
      }
    },
    handleResponseFromWebSocket(chunk) {
      if (this.loading) {
        const newMessage = {
          id: crypto.randomUUID(),
          author: { role: chatRoles.SYSTEM },
          words: [chunk],
          date: new Date()
        }

        this.messages[this.messageListLastIndex] = newMessage
        this.loading = false
        this.wordsListLastIndex = 0
      } else if (chunk.startsWith('<seez-sdk')) {
        this.messages[this.messageListLastIndex].words.push(chunk)
        this.messages[this.messageListLastIndex].words.push('')
        this.wordsListLastIndex += 2
      } else {
        this.messages[this.messageListLastIndex].words[this.wordsListLastIndex] += chunk
      }
    },
    sendMessageToWebSocket(message) {
      this.setLoadingMessage()
      this.disabled = true

      if (this.wsClosedDueToError) {
        setTimeout(() => this.replaceLoadingMessage(this.errorMessages.WEB_SOCKET_ERROR, true), 2000)
        return
      }

      if (this.wsConnection?.readyState != WebSocket.OPEN) {
        this.messageBuffer = message
        return
      }

      this.wsConnection.send(JSON.stringify({ operation: webSocketOperations.SEND_MESSAGE, message_in: message }))
    },
    sanitizeMessage(message) {
      const leadingWhitespace = message.match(/^\s*/)[0]

      const cleanMessage = message.replace(/<seez-sdk-[\w-]*\s*\/?>/g, '')

      const parser = new DOMParser()
      const parsed = parser.parseFromString(cleanMessage, 'text/html')

      return leadingWhitespace + (parsed.body.textContent || '')
    },
    handleNewMessage(newMessage) {
      const originalMessageText = newMessage.text
      const sanitizedMessageText = this.sanitizeMessage(originalMessageText)

      if (sanitizedMessageText === originalMessageText) {
        const messageObject = {
          id: crypto.randomUUID(),
          author: { role: chatRoles.USER },
          message: sanitizedMessageText,
          date: new Date()
        }

        const copy = [...this.messages, messageObject]
        this.messages = copy
        // this.sendMessageToAPI(messageObject)
        this.sendMessageToWebSocket(originalMessageText)
      }
    },
    handleFAQSelected(questionAsMessage) {
      this.handleNewMessage(questionAsMessage)
    },
    setLoadingMessage(message = this.defaultMessages.THINKING) {
      this.loading = true
      this.messages.push({
        id: chatStates.LOADING,
        author: { role: chatRoles.SYSTEM },
        message,
        date: new Date()
      })
    },
    changeLoadingMessageText(message) {
      if (!this.loading) return

      try {
        const messageObject = JSON.parse(message)
        this.messages[this.messageListLastIndex].message = this.toolMapping[messageObject?.preProcessing]?.text || this.defaultMessages.THINKING
      } catch (e) {
        console.log('Unable to parse pre-processing message:', e)
        this.messages[this.messageListLastIndex].message = this.defaultMessages.THINKING
      }
    },
    addClearListeners() {
      window.addEventListener('clearChat', this.onConfirmClearChat)
      window.addEventListener('cancelClearChat', this.cancelClearChat)
    },
    cancelClearChat() {
      const filteredMessages = this.messages.filter(item => item.id !== 'clear')
      this.messages = filteredMessages
      this.clearDisabled = false
    },
    setClearChatHistoryMessage() {
      this.messages.push({
        id: chatStates.CLEAR,
        author: { role: chatRoles.SYSTEM },
        message: `<seez-sdk-clear-chat id="${this.botId}"></seez-sdk-clear-chat>`,
        date: new Date()
      })
      this.scrollToBottom()
    },
    removeLoadingMessage() {
      this.loading = false
      const loadingMessageIndex = this.messages.findIndex(message => message.id === chatStates.LOADING)

      if (loadingMessageIndex !== -1) {
        this.messages.splice(loadingMessageIndex, 1)
      }
    },
    replaceLoadingMessage(message, isError = false) {
      this.trackInteractiveComponentReceived(message)
      const loadingMessageIndex = this.messages.findIndex(message => message.id === chatStates.LOADING)

      if (loadingMessageIndex !== -1) {
        const newMessage = {
          id: isError ? chatStates.ERROR : crypto.randomUUID(),
          author: { role: chatRoles.SYSTEM },
          message: message,
          date: new Date()
        }

        this.messages[loadingMessageIndex] = newMessage
        this.loading = false
      }
    },
    onClickTodaysMessageButton() {
      const currentDate = new Date().toISOString().split('T')[0]

      const messagesToday = this.messages.filter(message => {
        const messageDate = new Date(message.date).toISOString().split('T')[0]
        return messageDate === currentDate
      })

      if (messagesToday.length > 0) {
        const firstTodayMessage = messagesToday[0]
        const messagesContainer = this.$refs.messages
        const firstMessageContainer = messagesContainer.firstElementChild
        const targetMessageElement = firstMessageContainer.querySelector(`#${firstTodayMessage.id}`)

        if (targetMessageElement) {
          targetMessageElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }
    },
    onClickResetMessages() {
      this.clearDisabled = true
      this.setClearChatHistoryMessage()
      return
    },
    onConfirmClearChat() {
      this.messages = []
      this.clearDisabled = false
    },
    scrollToBottom() {
      if (this.noMessage) return
      this.$nextTick(() => {
        const messagesElement = this.$refs.messages

        if (messagesElement) {
          const scrollOptions = {
            top: messagesElement.scrollHeight,
            left: 0,
            behavior: 'smooth'
          }

          messagesElement.scrollTo(scrollOptions)

          setTimeout(() => {
            messagesElement.scrollTo({
              ...scrollOptions,
              top: messagesElement.scrollHeight + 90
            })
          }, 100)
        }
      })
    },
    switchTheme(isDark) {
      const targetConfig = isDark ? dark.chatConfig : light.chatConfig

      if (this.avatarStyles) {
        targetConfig.theme.chatAvatarStyles = this.avatarStyles
      }

      this.chatConfig = targetConfig
    },
    injectThemeStyles() {
      const styleSheet = document.createElement('style')
      const cssString = Object.entries(this.chatComponentStyles)
        .filter(p => p[1] != null)
        .map(p => `${p[0]}: ${p[1]};`)
        .join(' ')
      styleSheet.innerHTML = `.chat { ${cssString} }`
      styleSheet.setAttribute('data-custom', 'seez-chat')
      const rootNode = this.$el.getRootNode()
      rootNode.querySelectorAll('[data-custom="seez-chat"]').forEach(x => x.remove())
      const firstNoStyle = rootNode.querySelector(':not(style)')
      if (firstNoStyle) rootNode.insertBefore(styleSheet, firstNoStyle)
      else rootNode.prepend(styleSheet)
    },
    async trackInteractiveComponentReceived(message) {
      const components = message.match(/<seez-sdk-\w+(?:-[a-zA-Z]+)*\s+[^>]*>/g)

      if (!components) return

      const componentNameRegex = /<seez-sdk-(\S+)/
      const parser = new DOMParser()

      components.forEach(component => {
        const document = parser.parseFromString(component, 'text/html')
        const element = document.body.firstChild

        const properties = {}
        for (const attr of element.attributes) {
          properties[attr.name] = attr.value
        }

        let [, componentName] = component.match(componentNameRegex)
        componentName = componentName.replace('-', '_')

        this.track(`chat_${componentName}_sent`, properties)
      })
    },
    calculateFirstMessageDate() {
      if (this.messages.length) {
        const date = new Date(this.messages[0].date)
        date.setMinutes(date.getMinutes() - 1)
        this.cachedFirstMessageDate = date
        return date
      } else {
        const currentDate = new Date()
        this.cachedFirstMessageDate = currentDate
        return currentDate
      }
    },
    closeWebSocketConnection() {
      this.closeWsConnection = true
      this.wsConnection?.close()
    },
    openWebSocketConnection() {
      // open the connection if the chat is open && there is no connection or the connection state is not CONNECTING or OPEN
      if (this.isChatOpen && (!this.wsConnection || ![0, 1].includes(this.wsConnection?.readyState))) {
        this.establishWebSocketConnection()
      }
    },
    establishWebSocketConnection() {
      if (!this.botId) return

      this.closeWsConnection = false
      this.wsClosedDueToError = false

      this.wsConnection = new WebSocket(import.meta.env.VITE_CHAT_STREAMING_ENDPOINT)
      this.addWebSocketEventListeners()
    },
    createHeaders() {
      return {
        'client-id': window.seezSdk.clientId,
        'client-seezar-bot-id': this.botId,
        'seez-anonymous-id': localStorage.getItem('Seez-Anonymous-Id'),
        'seez-session-id': sessionStorage.getItem('Seez-Session-Id')
      }
    },
    setHandshakeResponseVariables(message) {
      try {
        const messageObject = JSON.parse(message)
        this.chatReferenceId = messageObject.chatRef || ''
        this.$parent.chatReferenceId = this.chatReferenceId
        this.chatUuid = messageObject.uuid || ''
        this.$parent.chatUuid = this.chatUuid
      } catch (e) {
        console.log('Unable to set handshake response variables', e)
      }
    },
    addWebSocketEventListeners() {
      const headers = this.createHeaders()

      this.wsConnection.addEventListener('message', event => {
        if (this.handShakeSent) {
          this.setHandshakeResponseVariables(event.data)
          this.checkForMessageBuffer()
          this.handShakeSent = false
        } else if (event.data.startsWith(`{"${webSocketOperations.PREPROCESSING}":`)) {
          this.changeLoadingMessageText(event.data)
        } else if (!reservedWebSocketStringsKeys[event.data] && !event.data.startsWith(`{"${webSocketOperations.ERROR}":`)) {
          this.handleResponseFromWebSocket(event.data)
        }

        if (event.data == reservedWebSocketStrings.END_OF_CHAT) {
          if (this.loading) this.replaceLoadingMessage(this.errorMessages.ERROR_LOADING_MESSAGE, true)

          this.disabled = false
          if (this.messages[this.messageListLastIndex].words) this.trackInteractiveComponentReceived(this.messages[this.messageListLastIndex].words.join(''))
          this.scrollToBottom()

          if (!this.isChatOpen) this.closeWebSocketConnection()
        }
      })

      this.wsConnection.addEventListener('open', () => {
        this.wsConnection.send(JSON.stringify({ operation: webSocketOperations.HANDSHAKE, headers }))
        this.handShakeSent = true
        this.errorsOccurred = 0

        this.pingPongInterval = setInterval(() => this.wsConnection.send(JSON.stringify({ operation: webSocketOperations.PING })), this.pingPongTimeGap * 60000)
      })

      this.wsConnection.addEventListener('error', () => {
        console.log('Web socket closed due to some error')

        this.errorsOccurred += 1
        if (this.errorsOccurred >= this.errorBuffer) {
          this.wsClosedDueToError = true
          this.closeWsConnection = true
          if (this.loading) this.replaceLoadingMessage(this.errorMessages.WEB_SOCKET_ERROR, true)
          return
        }

        if (this.loading) {
          this.messageBuffer = this.messages[this.messageListLastIndex - 1].message
        }
      })

      this.wsConnection.addEventListener('close', () => {
        clearInterval(this.pingPongInterval)

        if (!this.closeWsConnection) {
          console.log('Web socket closed, restarting websocket connection')
          this.establishWebSocketConnection()
        } else {
          console.debug('Web socket closed')
        }
      })
    },
    handleScroll() {
      const messagesElement = this.$refs.messages
      if (messagesElement.scrollTop < this.lastScrollTop) this.scrollingUp = true
      this.lastScrollTop = messagesElement.scrollTop
    },
    handleEndOfMessagesVisibility(isVisible) {
      if (isVisible) this.scrollingUp = false
      if (this.disabled && !this.scrollingUp && !isVisible) this.scrollToBottom()
    },
    externallyOpenChatModal(event) {
      this.$emit('onOpenChatModal')
      const externalMessage = event.detail?.message
      if (externalMessage) {
        if (!this.disabled) {
          this.handleNewMessage({
            text: externalMessage,
            sender: 'user'
          })
        } else {
          console.log('Bot is currently responding to a query')
        }
      }
    },
    checkForMessageBuffer() {
      if (this.messageBuffer) {
        this.wsConnection.send(JSON.stringify({ operation: webSocketOperations.SEND_MESSAGE, message_in: this.messageBuffer }))
        this.messageBuffer = ''
      }
    }
  }
}
</script>

<style lang="scss">
.chat {
  --base-blue: '#FFFFFF';
  --chat-secondary-button-bg-color: #fafafa;

  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.messages {
  place-self: stretch;
  overflow: auto;
}

.flexCenter {
  display: flex;
  align-items: center;
}

.noMessage {
  grid-template-rows: 1fr auto;
}

.chatInputWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0.2em;
  max-width: 45.125em;
  margin: auto;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, var(--chat-bg-color) 100%);
  direction: var(--text-direction);

  svg {
    width: 13px;
    height: 12px;
    cursor: pointer;
  }
}

.actionButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3.75em;
  font-size: 0.75em;
  text-align: center;

  > button {
    font-family: var(--font-family);
  }

  @media screen and (max-width: 420px) {
    flex-direction: column;
  }
}

.actionButtons.ltr {
  direction: ltr;
}

.actionButtons.rtl {
  direction: rtl;
}

.todaysMessageButton {
  border: none;
  background: transparent;
  max-width: 4.563em;
  cursor: pointer;
  color: var(--chat-base-text-color);
}

.chatReferenceId {
  margin: 0;
  color: var(--chat-base-text-color);
}

.disclaimerText {
  color: var(--chat-base-text-color);
  text-align: center;
  font-size: 0.625em;
  background-color: var(--chat-bg-color);
  max-width: 95%;
  min-width: 95%;

  @media screen and (max-width: 390px) {
    font-size: 0.563em;
  }
}

.customDisclaimerText {
  color: var(--chat-base-text-color);
  text-align: center;
  font-size: 0.625em;
  background-color: var(--chat-bg-color);
  max-width: 95%;
  min-width: 95%;
  padding: 1em 0;

  @media screen and (max-width: 390px) {
    font-size: 0.563em;
  }
}

.disclaimerText:first-of-type {
  padding-top: 1em;
  margin-left: auto;
  margin-right: auto;
}
.disclaimerText:last-of-type {
  padding-bottom: 1em;
  text-align: center;
  margin: auto;
}

.link {
  color: var(--link-color);
  text-decoration: underline;
  cursor: pointer;
}

.tooltipContainer {
  display: inline-block;
  position: relative;
  top: 2px;

  &.ltr {
    right: 2px;
  }
  &.rtl {
    left: 2px;
  }
}

.tooltipText {
  visibility: hidden;
  width: 250px;
  background-color: #fff;
  color: #262626;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  bottom: 125%;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow:
    0px -1px 13px 0px rgba(0, 0, 0, 0.15),
    0px 4px 12px 0px rgba(0, 0, 0, 0.13);

  &.ltr {
    left: 50%;
    margin-left: -12px;
  }
  &.rtl {
    right: 50%;
    margin-right: -12px;
  }
}

.tooltipText::after {
  content: '';
  position: absolute;
  top: 100%;
  margin-left: -8px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.tooltipText.ltr::after {
  left: 6%;
}

.tooltipText.rtl::after {
  right: 3%;
}

.tooltipContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}
</style>
